<template>
  <v-card elevation="0">
    <v-img
      contain
      style="position: relative"
      transition="scale-transition"
      height="190"
      :src="info.images[0]"
    >
      <div class="wrap-chips-img">
        <v-chip color="primary" small v-if="info.year">
          <b> {{ info.year }}</b></v-chip
        >
        <v-chip
          color="error"
          class="isExhausted ml-2"
          small
          v-if="info.isExhausted"
        >
          <b>Agotado</b></v-chip
        >
      </div>
    </v-img>
    <!-- <v-divider></v-divider> -->

    <v-card-text>
      <div class="chip-color mb-2">
        <div class="color-wrap">
          <div
            v-for="(i, index) in info.color"
            :style="`background-color:${i.color}`"
            :key="index"
            class="mr-1 color-c"
          ></div>
        </div>
      </div>
      <span class="font-weight-bold" style="color: #000">{{ info.name }}</span>
      <p class="font-weight-regular">{{ info.price | currency }}</p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "HelloWorld",
  props: ["info"],
  data: () => ({
    img: require("@/assets/motos/zonte01.webp"),
  }),
  filters: {
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
      });
      return amount ? `L ${formatter.format(amount)}` : `No disponible`;
    },
  },
};
</script>
<style lang="scss" >
.mtoo {
  min-width: 200px;
  scroll-snap-align: center;
  scroll-snap-stop: always;
  height: 100%;
  width: 250px;
  border-radius: 10px;
  margin-right: 5px;
  margin-left: 5px;
  padding: 10px;
  img {
    width: 100%;

    object-fit: cover;
  }
  &:hover {
    box-shadow: 0px 0px 5px #00000029;
  }
}
.chip-color {
  display: flex;
  justify-content: space-between;

  // position: absolute;
  // bottom: 0px;
  // right: 0;
}

.wrap-chips-img {
  position: relative;
  width: 100%;
  height: 100%;
}
// .isExhausted{
//   position: absolute;
//   right: 10px !important;
//   bottom: 10px !important;
// }
.color-wrap {
  width: 100%;
  display: flex;
  .color-c {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: solid #d8d8d8 2px;
  }
}
</style>
