<template>
  <v-app-bar app color="#000" dark class="elevation-shadow">
    <v-container class="flex">
      <img
        src="@/assets/LogoHondumotos-01.svg"
        style="float: left; cursor:pointer"
        class="logo"
        @click="goTolink('/#home')"
      />
      <v-btn
        small
        dark
        style="z-index: 100"
        class="isMobile-show"
        color="primary"
        icon
        @click="isOpen = !isOpen"
      >
        <v-icon>
          {{ isOpen ? "fa fa-close" : "fa-solid fa-bars" }}
        </v-icon>
      </v-btn>

      <div class="menulist">
        <ul>
          <li><a href="#home" @click="goTolink('/#home')">INICIO</a></li>
          <li>
            <a href="#about" @click="goTolink('/#about')">SOBRE NOSOTROS</a>
          </li>
          <v-menu open-on-hover offset-y transition="slide-y-transition">
            <template v-slot:activator="{ on, attrs }">
              <li
                color="primary"
                dark
                class="list-moto"
                v-bind="attrs"
                v-on="on"
              >
                <a>MOTOS</a>
              </li>
            </template>
            <v-list>
              <v-list-item
                v-for="i in sortBrands"
                :key="i[`.key`]"
                link
                to="/motorcycles"
                @click="addBrand(i)"
              >
                {{ i.name }}
              </v-list-item>
            </v-list>
          </v-menu>
     <li>
        <router-link to="/community">
          COMUNIDAD
        </router-link>
        </li>
          <li>
            <a href="#contact" @click="goTolink('/#contact')">CONTÁCTANOS</a>
          </li>
        </ul>
      </div>
    </v-container>
    <div class="menulistMobile" v-if="isOpen">
      <ul>
        <li>
          <a href="#home" @click="goTolink('/#home')">INICIO</a>
        </li>
        <li>
          <a href="#about" @click="goTolink('/#about')">SOBRE NOSOTROS</a>
        </li>
        <li color="primary" dark class="list-moto">
          <v-menu  offset-y transition="slide-y-transition">
            <template v-slot:activator="{ on, attrs }">
              <a v-bind="attrs" v-on="on">MOTOS</a>
            </template>
            <v-list>
              <v-list-item
                v-for="i in pagesBrands"
                :key="i[`.key`]"
                link
                to="/motorcycles"
                @click="addBrand(i), (isOpen = false)"
              >
                {{ i.name }}
              </v-list-item>
            </v-list>
          </v-menu>
        </li>

      
        <li>
          <a  @click="goTolink('/community')">COMUNIDAD</a>
        </li>
        <li>
          <a href="#contact" @click="goTolink('/#contact')">CONTÁCTANOS</a>
        </li>
      </ul>
    </div>
  </v-app-bar>
</template>
<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";
export default {
  name: "navBar",
  data() {
    return {
      isOpen: false,
      pagesBrands: [],
    };
  },
  methods: {
    ...mapActions(["addBrand", "Alert_"]),
    goTolink(url) {
      let rute = this.$router.currentRoute;
      console.debug(rute.name);
      if (rute.name == "home") {
        this.$router.push({ path: url });
      } else {
        if (url.includes("motorcycle")) {
          this.$router.push({ path: url });
          this.isOpen = false;
        } else {
          this.$router.push({ path: url });
          this.isOpen = false;
        }
      }
      this.isOpen = false;
      //    :to="{ path: `/${this.nameIdC}`, params: { _id: this.nameIdC } }"
    },
  },
  computed:{
 sortBrands() {

      function compare(a, b) {
        if (a.position < b.position) return -1;
        if (a.position > b.position) return 1;
        return 0;
      }
      return this.pagesBrands.sort(compare);
    },
  },
  mounted() {
    this.$binding(
      "pagesBrands",
      db.collection("brands").where("active", "==", true)
    );
  },
};
</script>
<style lang="scss" scoped>
@import "@/_responsive.scss";
.elevation-shadow {
  box-shadow: 0px 0px 10px #0000002e !important;
}

.menulist {
  position: relative;

  ul {
    list-style-type: none;
    // overflow: hidden;
    // display: flex;
    // flex-direction: column;
  }

  li {
    float: left;
    @include responsive(mobile) {
      float: none;
      flex-direction: column;
      height: auto !important;
      //... all what you want
    }
    margin-left: 10px;
  }

  li a {
    display: block;
    color: #fff;
    text-align: center;
    padding: 5px 20px;
    text-decoration: none;
    transition: 0.3s ease-in-out;
  }

  li a:hover {
    background-color: #dbe400;
    border-radius: 5px;
    color: #000;
  }
}
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  .logo {
    height: 30px;
    @include responsive(mobile) {
      z-index: 100;
    }
  }
  .menulist {
    display: block;
    @include responsive(mobile) {
      display: none;
    }
  }
  @include responsive(mobile) {
    float: none;
    flex-direction: row;
    height: auto !important;
    //... all what you want
  }
}
.menulistMobile {
  ul {
    list-style-type: none;
    // overflow: hidden;
    // display: flex;
     transform: translateX(-10px);
    // flex-direction: column;
  }
 

  li {
    float: left;
    @include responsive(mobile) {
      float: none;
      flex-direction: column;
      height: auto !important;
      //... all what you want
    }
    margin-left: 10px;
  }

  li a {
    display: block;
    color: #fff;
    text-align: center;
    padding: 5px 20px;
    font-size: 1.5em;
    text-decoration: none;
    transition: 0.3s ease-in-out;
  }

  li a:hover {
    background-color: #dbe400;
    border-radius: 5px;
    color: #000;
  }
  display: none;
  @include responsive(mobile) {
    display: block;
    position: absolute;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #000;
    top: 0;
    left: 0;
    z-index: 1;
  }
}
.isMobile-show {
  display: none;
  @include responsive(mobile) {
    display: block;
  }
}
</style>