<template>
  <transition name="fade">
    <div
      v-if="show"
      class="loader"
      :class="{ 'color-bg-compras': !isMenu, 'color-bg-menu': isMenu }"
      :style="`background-color: ${colorBG};`"
    >
      <div class="loader" :style="`background-color: ${colorBG};`">
   <svg version="1.1" id="Layer_1" class="logo-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 455.6 451.9" style="enable-background:new 0 0 455.6 451.9;" xml:space="preserve">
<path class="st0" d="M226.4,1.1c124.4-0.4,230.7,103.5,224.7,236.3c-5.3,116.5-102.1,215.6-228,214C98.7,449.8,0.1,348,1,224.6
	C1.8,100.2,103.5,0.6,226.4,1.1z M27.4,226.1c0,110.3,89.2,198.2,197.3,198.9c110.2,0.7,199.8-88.1,200.2-197.8
	C425.3,116.5,336.1,27.3,226,27.4C115.7,27.5,27.5,116.8,27.4,226.1z"/>
<path class="st0" d="M225.9,239.5c-16.7,0-33.4,0-50.2,0c-41.3,0-82.6,0.1-124,0.1c-4,0-5.8-1.7-5.8-5.7c-0.1-7.2,0-14.4,0-21.6
	c0-3.7,1.9-5.5,5.7-5.5c36.1-0.1,72.3-0.1,108.4-0.2c79.8-0.1,159.6-0.1,239.4-0.2c4.2,0,6,1.6,6.1,5.8c0.1,7.2,0.1,14.5,0,21.8
	c-0.1,3.4-1.7,4.8-5.1,4.8c-57.1,0.2-114.3,0.3-171.4,0.5c-1,0-2.1,0-3.1,0C225.9,239.4,225.9,239.5,225.9,239.5z"/>
<path class="st0" d="M98,197.6c-8.2,0-16.5,0-25,0c0-0.6,0-1.4,0-2.1c0.1-17,4.3-33,10.9-48.5c7.2-17,16.7-32.8,29-46.7
	c6.8-7.6,14.2-14.5,23.1-19.6c0.8-0.5,1.7-0.9,2.7-1.4c0.7,1.2,1.3,2.3,1.9,3.5c2.9,5.9,5.7,11.8,8.6,17.6c0.8,1.6,0.4,2.4-1.1,3.3
	c-11,6.8-19,16.5-26.3,26.9c-0.1,0.2-0.2,0.5-0.3,0.7c69.7,0,139.2,0,209.2,0c-3.9-6-8-11.3-12.6-16.2c-4.6-4.9-9.6-9.3-15.8-12.8
	c3.9-7.7,7.7-15.2,11.8-23.3c4,2.7,7.7,5,11.1,7.8c16,12.9,27.4,29.4,36.7,47.4c6.8,13.2,12.1,27.1,14.8,41.8
	c1.1,6.1,1.5,12.3,2.1,18.4c0.2,1.9-0.6,2.8-2.6,2.8c-7.1,0-14.2,0-21.3,0.1c-1.3,0-1.6-0.6-1.6-1.8c-0.1-14-4.2-27-9.3-39.8
	c-0.6-1.6-1.7-1.5-2.9-1.5c-15.7,0.1-31.5,0.1-47.2,0.1c-26.4,0-52.8,0-79.2,0c-34.6,0-69.2,0.1-103.8,0c-1.8,0-2.5,0.6-3.1,2.1
	c-3.8,9-6.9,18.2-8.3,27.9C98.6,188.6,98.4,193,98,197.6z"/>
<path class="st0" d="M73.5,248.7c8.4,0,16.6,0,25,0c0,0.2,0.2,0.5,0.2,0.7c-0.4,13,3.2,25.1,7.9,37c6.7,16.8,15.7,32.1,28.3,45.3
	c1.4,1.4,2.9,2.7,4.3,4.2c1.2,1.3,2.2,1.3,3.7,0.3c21.8-14.7,43.7-29.4,65.6-44c5.6-3.7,11.1-7.4,16.6-11.3c1.1-0.7,1.7-0.6,2.7,0
	c27.3,18.3,54.6,36.6,81.9,55c1.7,1.2,2.7,1,4.1-0.4c15.7-14.9,26.2-33.1,33.7-53.1c3-8,5.2-16.2,6.1-24.7c0.3-3.1,0.1-6.2,0.1-9.5
	c8.9,0,17.2,0,25.8,0c-0.2,3.9-0.2,7.8-0.6,11.7c-2.8,24.9-12.6,47.2-25.9,68.1c-8,12.5-17.4,23.7-29.4,32.6
	c-2.3,1.7-4.7,3.1-7.1,4.7c-1.2,0.8-2.1,0.7-3.3-0.1c-27.1-18.2-54.3-36.4-81.4-54.6c-1.8-1.2-3.6-3.2-5.4-3.2c-1.8,0-3.6,2-5.4,3.2
	c-26.8,18-53.7,36-80.5,54.1c-2.1,1.4-3.6,1.3-5.7,0.1c-11.7-7.2-21-16.9-29.2-27.9c-14-18.8-24-39.6-29.6-62.4
	C74.1,266.4,72.9,255.7,73.5,248.7z"/>
</svg>

      </div>
    </div>
  </transition>
</template>

<script>
// import Lottie from "./Lottie";
// import { mapGetters } from "vuex";
// // import store from "@/store/index.js";
// import * as preloader from "@/assets/Lottie/preloader.json";
// import * as compras from "@/assets/Lottie/compras.json";
// import * as ocho from "@/assets/Lottie/data.json";
// import * as Caminanding from "@/assets/Lottie/caminando.json";
// import * as deleteOrder from "@/assets/Lottie/delete.json";

export default {
  name: "Preloader",
  components: {
    // lottie: Lottie,
  },
  props: {},
  data() {
    return {
      colorBG: "#dbe400",
      color: "",
      isMenu: true,
      anim: null,

      show: true,
      show_lottie: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.show_lottie = true;
        this.showLoader();
      }, 500);
    });
  },
  methods: {
    showLoader() {
      window.onload = setTimeout(() => {
        this.show = false;
      }, 3000);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">
$BG: #d60303;

@import "@/_responsive.scss";
.logo-svg{
  width: 200px;
  height: 200px;
}
.loader {
  width: 100vw;
  height: 100vh;
  // background-color: $BG;

  z-index: 1000 !important;
  display: flex;
  pointer-events: none;
  //  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  overflow: hidden;
  position: fixed;
  flex-direction: column;
  .lottie-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 300px;
  }
}
.color-bg-menu {
  background-image: radial-gradient(
    circle at 50% 50%,
    #00cdbc,
    #00c3cd
  ) !important;
}
.color-bg-compras {
  background-image: radial-gradient(
    circle at 50% 50%,
    #ffc634,
    #ffda7c
  ) !important;
}
@include responsive(mobile) {
  .lottie-content {
    width: 75% !important;
  }
}
@include responsive(desktop) {
  .lottie-content {
    width: 250px !important;
    // height: 100px;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.by-ocho {
  position: absolute;
  bottom: 15vh;
}

.st0 {
  fill: none;
  stroke: #000000d9;
  stroke-width: 2;
  stroke-miterlimit: 0;
  stroke-dashoffset: 0;
  stroke-dasharray:1000;
  animation: circle_stroke 2s ease-in forwards;
}

@keyframes circle_stroke {
  0% {
    stroke-dashoffset: 2000;
        fill: rgba(255, 255, 255, 0);
  }
  40%{
    fill: rgba(255, 255, 255, 0);

  }
  100% {
    stroke-dashoffset: 0;
    fill: #000;
  }
}
</style>