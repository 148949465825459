import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import firebase from "firebase/compat/app";
import VueFirestore from "vue-firestore";
import './main.scss'
Vue.config.productionTip = false
Vue.use(VueFirestore);
Vue.use(firebase);

Object.defineProperty(Vue.prototype, "firebase", {
  get() {
    return this.$root.firebase;
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
