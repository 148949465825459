import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";

let env = process.env.NODE_ENV;

var devConfig = {
  apiKey: "AIzaSyBqug70aDbDsMepTEWEq5oCiBXi4nBfO18",
  authDomain: "hondumotos-2f930.firebaseapp.com",
  projectId: "hondumotos-2f930",
  storageBucket: "hondumotos-2f930.appspot.com",
  messagingSenderId: "62661044341",
  appId: "1:62661044341:web:c2b6b7952fd5f6e816703c",
  measurementId: "G-4GX8GZPX1W"
};

var prodConfig = {
  apiKey: "AIzaSyBqug70aDbDsMepTEWEq5oCiBXi4nBfO18",
  authDomain: "hondumotos-2f930.firebaseapp.com",
  projectId: "hondumotos-2f930",
  storageBucket: "hondumotos-2f930.appspot.com",
  messagingSenderId: "62661044341",
  appId: "1:62661044341:web:c2b6b7952fd5f6e816703c",
  measurementId: "G-4GX8GZPX1W"
};

firebase.initializeApp(env == "production" ? prodConfig : devConfig);

// if (env == "development") {
// firebase.functions().useFunctionsEmulator("http://localhost:5001");
// }

firebase.auth().languageCode = "es";

export const db = firebase.firestore();
export const fb = firebase;
