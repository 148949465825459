<template>
  <v-app>
    <preloader />
    <navbar />

    <v-main>
      <router-view />
    </v-main>
    <footerComp />
  </v-app>
</template>

<script>
import footerComp from "@/components/footer.vue";
import MotoList from "@/components/motosList.vue";
import preloader from "@/components/preloader.vue";
import navbar from "@/components/nav.vue";
export default {
  name: "App",
  components: {
    footerComp,
    MotoList,
    preloader,
    navbar,
  },
  data: () => ({
    //
    items: [
      { title: "Click Me" },
      { title: "Click Me" },
      { title: "Click Me" },
      { title: "Click Me 2" },
    ],
  }),
};
</script>
<style lang="scss">
html {
  scroll-behavior: smooth !important;
}
</style>
