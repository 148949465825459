<template>
  <div class="home-wrap mb-12" id="home">
    <v-carousel
      :height="isMobileSz ? '30vh' : '100vh'"
      hide-delimiter-background
      cycle
      background-color="primary"
      transition="slide-x-reverse-transition"
      :class="{ 'mb-12': !isMobileSz, 'mb-2': isMobileSz }"
      show-arrows-on-hover
    >
      <template v-slot:prev="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon color="primary"> fa-solid fa-chevron-left </v-icon></v-btn
        >
      </template>
      <template v-slot:next="{ on, attrs }">
        <v-btn color="primary" icon v-bind="attrs" v-on="on"
          ><v-icon> fa-solid fa-chevron-right </v-icon></v-btn
        >
      </template>
      <v-carousel-item
        v-for="(item, i) in sorthomeBanners"
        :key="i"
        :src="item.url"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
      </v-carousel-item>
    </v-carousel>
    <v-container id="about" class="mb-12">
      <h1 class="title-pintia font-weight-light text-center mb-12">
        Grupo Hondumotos S.A.
      </h1>
      <p class="mt-6 px-6 font-weight-light">
        Grupo Hondumotos S.A. es una empresa hondureña dedicada a la
        distribución y comercialización de motocicletas de pequeña y mediana
        cilindrada con los más altos estándares de calidad. Ofrecemos
        motocicletas deportivas, doble propósito y todo terreno y contamos con
        el respaldo de fabricas mundialmente reconocidas como Tayo Motorcycle
        Technology y Chongquing Bull. Somos una empresa responsable que busca
        ofrecer una amplia selección de modelos que se adecuan a la necesidad de
        cada cliente. Con las marcas
        <b>ZONTES, GPX, Super SOCO y VENTO</b> Hondumotos busca innovar y
        ofrecer la mas avanzada tecnología en motociclismo a nivel nacional.
      </p>
    </v-container>
    <v-container class="text-center my-12">
      <span class="distri text-center"> DISTRIBUIDOR EXCLUSIVO</span>
      <div class="logos-marcas mt-6 px-12">
        <img src="@/assets/LOGO BLANCO PNG(2).webp" alt="" />

        <img src="@/assets/GPX Logo Blanco-01.webp" alt="" />
        <img src="@/assets/super-soco.svg" alt="" />
        <img src="@/assets/vento.svg" alt="" />
      </div>
    </v-container>

    <v-container class="mb-12" id="contact">
      <v-card elevation="0" color="#f2f2f2">
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6" lg="6" sm="12">
              <h2>Contactános</h2>
              <v-form class="mt-4" ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  v-model="form.name"
                  rounded
                  style="border-radius: 0px"
                  label="Nombre"
                  :rules="nameRules"
                  name="name"
                  required
                  filled
                ></v-text-field>

                <v-text-field
                  v-model="form.email"
                  rounded
                  style="border-radius: 0px"
                  :rules="emailRules"
                  label="Correo"
                  required
                  name="email"
                  filled
                ></v-text-field>
                <v-text-field
                  v-model="form.phone"
                  rounded
                  style="border-radius: 0px"
                  name="numero"
                  label="Celular"
                  :rules="nameRules"
                  required
                  filled
                ></v-text-field>
                <v-textarea
                  label="Comentario"
                  rounded
                  style="border-radius: 0px"
                  v-model="form.message"
                  :rules="nameRules"
                  name="message"
                  filled
                ></v-textarea>

                <!-- <input type="hidden" name="_next" value="https://gutierrez-asocciados.web.app/" /> -->
                <input type="hidden" name="_captcha" value="false" />
                <v-btn
                  class="mr-4"
                  block
                  :disabled="!valid"
                  @click="validate"
                  light
                  color="primary"
                  height="50"
                >
                  Enviar
                </v-btn>
              </v-form>
            </v-col>
            <v-col ols="12" md="6" lg="6" sm="12">
              <v-img src="@/assets/local-2.png" height="100%"> </v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
   
    <v-container class="bg-agencias text-center pt-12" dark>
      <!-- <h1 class="font-weight-light  text-center my-12">Agencias</h1> -->
      <img
            src="@/assets/LogoHondumotos-01.svg"
            width="270px"
            alt=""
            srcset=""
          />
      <v-row>
        <v-col cols="12" md="6" sm="6" lg="6">
        <h2 style="color: #dbe400">Tegucigalpa</h2>
        <p class="text-center">
            Segunda Avenida, 11 Calle
        
            Edificio de Esquina
            <br />
            Comayagüela, Honduras
          </p>
          <span class="text-center" style="color: #dbe400">Horario</span>
          <p class="text-center">
            Lunes a Viernes: 8:00 am - 5:00 pm
            <br />
            Sábado: 8:00 - 12:00 pm
            <br />
            Domingo: Rodando
          </p>
          <v-btn
            color="primary"
            class="px-12"
            style="color: #000"
            link
            target="_blank"
           href="https://maps.app.goo.gl/697JYrauGTS553ux8"
          >
            Ver mapa
          </v-btn>
        </v-col>
        <v-col cols="12" md="6" sm="6" lg="6">
        <h2 style="color: #dbe400">San Pedro Sula</h2>
        <p class="text-center">
          Local contiguo a Gasolinera Uno Frente a la Fuente Luminosa,<br>San Pedro Sula, Cortés
          </p>
          <span class="text-center" style="color: #dbe400">Horario</span>
          <p class="text-center">
            Lunes a Viernes: 8:00 am - 5:00 pm
            <br />
            Sábado: 8:00 - 12:00 pm
            <br />
            Domingo: Rodando
          </p>
          <v-btn
            color="primary"
            class="px-12"
            style="color: #000"
            link
            target="_blank"
           href="https://maps.app.goo.gl/1fsrnRQxiz6oFBBLA"
          >
            Ver mapa
          </v-btn>
        
        </v-col>
      
       
        
      </v-row>
    </v-container>

    <v-dialog v-model="dialogMapa" v-if="dialogMapa" scrollable>
      <v-card>
        <v-card-title>
          Como llegar
          <v-btn
            fab
            small
            color="primary"
            absolute
            right
            @click="dialogMapa = false"
          >
            <v-icon small color="#000"> fa fa-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1009.812096437347!2d-87.20822006668197!3d14.094559908383415!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f6fa33542e21711%3A0x3dbbe5e5b6ed98d6!2sHondumotos!5e0!3m2!1ses-419!2shn!4v1677731041596!5m2!1ses-419!2shn"
            width="100%"
            height="500px"
            style="border: 0; border-radius: 10px"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </v-card-text>
      </v-card>
    </v-dialog>
 

  </div>
</template>

<script>
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { db, fb } from "@/firebase";

export default {
  name: "Home",
  data() {
    return {
      dialogGalery:false,
      selectGalery:null,
      dialogMapa: false,
      toDay: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      homeBanners: [],
      galery: [],
      valid: true,
      isMobileSz: window.screen.width < 700 ? true : false,
      nameRules: [(v) => !!v || "Campo Requerido"],
      emailRules: [
        (v) => !!v || "Campo Requerido",
        (v) => /.+@.+\..+/.test(v) || "Correo no valido",
      ],
      form: {
        name: "",
        email: "",
        phone: null,
        message: "",
      },
    };
  },
  computed: {
    sorthomeBanners() {
      let arrayBanners = this.validSchedule(this.homeBanners);
      function compare(a, b) {
        if (a.position < b.position) return -1;
        if (a.position > b.position) return 1;
        return 0;
      }
      return arrayBanners.sort(compare);
    },
    sorthomeGalery() {
      function compare(a, b) {
        if (a.position < b.position) return -1;
        if (a.position > b.position) return 1;
        return 0;
      }
      return this.galery.sort(compare);
    },
  },
  methods: {
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.form.createdAt = new Date();
        db.collection("contactForm")
          .add(this.form)
          .then((response) => {
            console.debug(response);
            this.form = {};
            this.reset();
          });
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    validSchedule(array) {
      console.debug(array);
      return array.filter((elemnet) =>
        moment(this.toDay).isBetween(
          moment(elemnet.dateStart.toDate()).format("YYYY-MM-DD"),
          moment(elemnet.dateEnd.toDate()).format("YYYY-MM-DD")
        )
      );
    },
  },
  async mounted() {
    await this.$binding(
      "homeBanners",
      db
        .collection("banners")
        .where("deleted", "==", false)
        .where("active", "==", true)
    );
    await this.$binding(
      "galery",
      db
        .collection("galery")
        .where("deleted", "==", false)
        .where("active", "==", true)
    );
  },
};
</script>
<style scoped lang="scss">
@import "@/_responsive.scss";
.home-wrap {
  width: 100vw;

  height: 100%;
}

.title-pintia {
  border-bottom: 2px solid #dbe400;
}

.logos-marcas {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;

  img {
    width: 200px;
    margin: 10px;
  }

  margin-bottom: 50px;
}

.distri {
  letter-spacing: 10px;
}
.bg-agencias {
  background-color: #000000a5 !important;
  background-image: url(../assets/local-1.png);
  background-position: center;
  background-attachment: fixed;

  @include responsive(mobile) {
    background-attachment: inherit !important;
  }

  background-blend-mode: soft-light;
  background-size: cover;
  color: #fff;
  margin-top: 50px;
  margin-bottom: 50px;
  padding-bottom: 50px;
}

.image-galery {
  transition: 0.3s ease-in-out;
  &:hover {
    cursor: pointer;
    z-index: 1;
    border: #dbe400 solid 3px;
    box-shadow: 0px 5px 10px #0000001f;
  }
}
</style>
